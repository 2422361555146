<template>
    <div class="company-details">
        <NoButtonModal :open="open" id="the_company_details_modal">
            <div class="modal-content-header">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Mobex Health Hub</span>
                <b-tooltip target="close" placement="bottom" title="Close"></b-tooltip>
                <div id="close" class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle" role="button"
                    style="top: 10px" @click="$emit('close')">
                    close
                </div>
            </div>
            <div class="modal-content-container">
                <div class="w-100 d-flex">
                    <div class="w-30"></div>
                    <div class="w-70">
                        <img :src="companyDetails.company_logo_URL" alt=""
                            style="max-width: 200px; max-height:100px; margin-left: 10px" />
                    </div>
                </div>
                <div class="w-100 d-flex" style="align-items: center; gap: 20px">
                    <div class="w-30 text-label">Company Name</div>
                    <div class="w-70 text-field">{{ companyDetails.company_name }}</div>
                </div>
                <div class="w-100 d-flex" style="align-items: center; gap: 20px">
                    <div class="w-30 text-label">Company Email</div>
                    <div class="w-70 text-field">{{ companyDetails.company_email }}</div>
                </div>
                <div class="w-100 d-flex" style="align-items: center; gap: 20px">
                    <div class="w-30 text-label">Phone Number</div>
                    <div class="w-70 text-field">{{ companyDetails.phone }}</div>
                </div>
                <div class="w-100 d-flex" style="align-items: center; gap: 20px">
                    <div class="w-30 text-label">Company Website (URL)</div>
                    <div class="w-70 text-field">{{ companyDetails.company_website }}</div>
                </div>
                <div class="w-100 d-flex" style="align-items: center; gap: 20px">
                    <div class="w-30 text-label">Address</div>
                    <div class="w-70 text-field">{{ companyDetails.address }}.</div>
                </div>
                <div class="w-100 d-flex" style="align-items: center; gap: 20px">
                    <div class="text-label" style="width: 28% !important">City</div>
                    <div class="w-70 d-flex">
                        <div class="text-field" style="width: 35%;">{{ companyDetails.city }}</div>
                        <div class="d-flex" style="align-items: center; gap: 20px;width: 65%;">
                            <div class="w-30 text-label">State</div>
                            <div class="w-70 text-field">{{ companyDetails.state }}</div>
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex" style="align-items: center; gap: 20px">
                    <div class="w-30 text-label">Zip codes</div>
                    <div class="w-70 text-field">{{ companyDetails.zip }}</div>
                </div>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';

export default {
    name: 'CompanyDetailsModal',
    components: { NoButtonModal },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        companyDetails: {
            type: Object,
            required: true,
        },
    },
    methods: {},
};
</script>

<style scoped>
.modal-content-header {
    background-color: #f1f7ff;
    padding: 10px;
    display: flex;
    align-items: center;
}

.modal-content-container {
    padding: 20px 10% 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 80%;
    overflow: auto;
    width: -webkit-fill-available;
}

.text-field {
    border: 0.1rem solid #5155c3;
    border-radius: 10px;
    padding: 8px 15px;
    color: #5155c3;
    font-weight: 500;
    min-height: 15px;
}

.text-label {
    color: #7d7f81;
    font-weight: 600;
    text-align: end;
}
/* ### Custom scroll ### */

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}
</style>

<style>
.company-details .modal__content {
    padding: 0;
    border-radius: 10px;
    max-width: 60vw !important;
    max-height: 75vh !important;
    overflow: hidden;
    height: 100%;
}
</style>